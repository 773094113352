<template>
  <label class="mb-2">
    <div class="b lh-title">
      <slot></slot>
    </div>

    <div class="flex flex-column" v-if="errors">
      <div class="dark-red" v-for="error in errors" v-bind:key="error">{{ error }}</div>
    </div>

    <textarea v-if="type === 'textarea'" ref="input" :class="inputClasses" class="br3 ba b--light-silver w-100 mt2"
      rows="3" :placeholder="placeholder" v-model="value"></textarea>
    <input v-else :class="inputClasses" ref="input" class="mt2 mb4 br3 ba b--light-silver w-100"
      :placeholder="placeholder" :type="type" v-model="value" />
  </label>
</template>

<script>
export default {
  name: 'inputField',
  emits: ['update:modelValue'],
  props: {
    required: { type: Boolean, default: true },
    errors: { type: Array, default: () => [] },
    type: { type: String, default: 'text' },
    modelValue: { type: String, required: true },
    placeholder: { type: String, required: true }
  },

  mounted () {
    if (this.required) this.$refs.input.setAttribute('required', true);
  },

  methods: {
    updateTextareaHeight () {
      this.$refs.input.style.height = 'auto';
      this.$refs.input.style.height = this.$refs.input.scrollHeight + 'px';
    }
  },

  computed: {
    inputClasses () {
      return this.errors.length ? 'b--red bg-washed-red' : '';
    },

    value: {
      get () {
        return this.modelValue;
      },

      set (val) {
        this.$emit('update:modelValue', val);
        if (this.type === 'textarea') this.updateTextareaHeight();
      }
    }
  }
};
</script>

<style scoped>
input, textarea {
  padding: 1em;
}
</style>
